<template>
  <div>
    <v-container fluid style="padding-bottom: 10vh;">
      <Menubar />
      <v-main>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <v-scroll-x-reverse-transition hide-on-leave>
              <router-view></router-view>
            </v-scroll-x-reverse-transition>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>

<script>
import Menubar from './nav'
import store from '../../store'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  components: {
    Menubar,
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      template: 'template',
    }),
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    startTimer() {
      store.dispatch('setTimer')
      setTimeout(() => {
        this.startTimer()
      }, 1000)
    },
  },
}
</script>
